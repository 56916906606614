

























import { defineComponent } from "@vue/composition-api";
import { environmentVariables } from "@monorepo/utils/src/variables/environmentVariables";
import TreeNode from "@monorepo/uikit/src/components/tableViews/TreeNode.vue";

export default defineComponent({
  name: "CaseTree",
  components: {
    TreeNode,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getTreeItems() {
      this.treeItems = [
        {
          id: "1",
          title: "Фонд 1",
          isFolder: true,
          isFund: true,
          fundCode: "435678984",
          startAndEndFund: "2022-10-06 / 2022-11-12",
          children: [
            {
              id: "11",
              title: "105802",
              isCase: true,
              isFolder: true,
              name: "Опись документов #1894",
              archive: "1456-2356",
              test: "1456-2356",
              children: [
                {
                  id: "111",
                  isCommonHeader: true,
                  number: "b67e34c3-3c3e-4b83-9438-ada3c0e686a5",
                  status: "Получено",
                  name: "Документ №1a1c1f415-0929- 49e6-990c-d8bb4ba9bd9c",
                  date: "2022-10-07",
                  index: "3214",
                  isDoc: true,
                },
                {
                  id: "1112",
                  isHideHeader: true,
                  number: "b67e34c3-3c3e-4b83-9438-ada3c0e686a5",
                  status: "Получено",
                  name: "Документ №1a1c1f415-0929- 49e6-990c-d8bb4ba9bd9c",
                  date: "2022-10-07",
                  index: "3214",
                  isDoc: true,
                },
              ],
            },
          ],
        },
      ];
    },
  },
  data() {
    return {
      headers: {
        fund: [
          { key: "fundCode", title: "Код фонда", width: "192px" },
          { key: "startAndEndFund", title: "Начало/конец фонда", width: "200px" },
        ],
        case: [
          { key: "name", title: "Код фонда", width: "225px" },
          { key: "archive", title: "Архивный шифр дела", width: "193px" },
          { key: "test", title: "Архивный шифр дела", width: "192px" },
        ],
        document: [
          { key: "number", title: "Номер документа", width: "208px", isNoWrap: true },
          { key: "status", title: "Статус", width: "134px", isNoWrap: true },
          { key: "name", title: "Наименование", width: "260px", isNoWrap: true },
          { key: "date", title: "Дата регистрации в сэд", width: "181px", isNoWrap: true },
          { key: "index", title: "Индекс дела", width: "238px", isNoWrap: true },
        ],
      },
      treeItems: [] as Record<string, unknown>[],
      folder: `${environmentVariables.BASE_URL}assets/images/common/foleder.svg`,
    };
  },
  created() {
    this.getTreeItems();
  },
});
