












































import { defineComponent } from "@vue/composition-api";
import { v4 as uuid } from "uuid";
import { environmentVariables } from "@monorepo/utils/src/variables/environmentVariables";
import TreeNode from "@monorepo/uikit/src/components/tableViews/TreeNode.vue";
import EadStatus from "@monorepo/catalog/src/views/FundView/components/EadStatus.vue";
import InventoryStatus from "@monorepo/uikit/src/components/tableViews/InventoryCountItemsStatus.vue";
import { mapActions, mapGetters } from "vuex";
import { TreeItem } from "@monorepo/catalog/src/views/FundView/types/treeItem";
import moment from "moment";
import InfiniteScroll from "@monorepo/uikit/src/components/tableViews/InfiniteScroll.vue";
import { useDynamicTree } from "@monorepo/utils/src/composables/useDynamicTree";

const convertData = (date: string) => {
  return date ? moment(date).format("YYYY-MM-DD") : "-";
};

const pause = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export default defineComponent({
  name: "CaseTree",
  components: {
    TreeNode,
    InventoryStatus,
    EadStatus,
    InfiniteScroll,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("fundCatalogView", ["treeData", "infiniteId"]),
  },
  watch: {
    treeData(items) {
      this.treeItems = items.map((fundTreeItem: TreeItem) => {
        return {
          code: fundTreeItem.code,
          id: uuid(),
          startAndEndFund: `${convertData(fundTreeItem.dateFirstArrival)}/${convertData(fundTreeItem.dateLastArrival)}`,
          title: fundTreeItem.name,
          isFund: true,
          isFolder: true,
          children: (fundTreeItem.inventoryHierarchy || []).map((invTreeItem) => {
            return {
              title: invTreeItem.number,
              header: invTreeItem.header,
              type: invTreeItem.type,
              id: uuid(),
              kind: invTreeItem.kind,
              fund: fundTreeItem.number,
              isInv: true,
              isFolder: true,
              children: (invTreeItem.caseHierarchyList || []).map((caseTreeItem) => {
                return {
                  title: caseTreeItem.number,
                  id: uuid(),
                  name: caseTreeItem.header,
                  isCase: true,
                  isFolder: true,
                  archive: caseTreeItem.archiveCipher,
                  receivedEadCount: caseTreeItem.receivedEadCount,
                  totalEadCount: caseTreeItem.totalEadCount,
                  fundNumber: fundTreeItem.number,
                  index: caseTreeItem.index,
                  children: [],
                };
              }),
            };
          }),
        };
      });
    },
  },
  data() {
    return {
      open: [],
      treeItems: [],
      active: [],
      headers: {
        fund: [
          { key: "code", title: "Код фонда", width: "192px", isLink: true },
          { key: "startAndEndFund", title: "Начальная/конечная дата", width: "200px" },
        ],
        inventory: [
          { key: "header", title: "Заголовок", width: "250px", isLink: true },
          { key: "kind", title: "Вид описи", width: "200px" },
          { key: "type", title: "Тип описи", width: "200px" },
        ],
        case: [
          { key: "doc", slotName: "doc", title: "Документы", tooltip: "Получено документов/Всего документов", width: "120px" },
          { key: "name", title: "Заголовок", tooltip: "Заголовок дела", width: "150px", isLink: true },
          { key: "archive", title: "Архивный шифр", tooltip: "Архивный шифр дела", width: "301px" },
          { key: "index", title: "Индекс", tooltip: "Индекс дела", width: "100px" },
        ],
        document: [
          { key: "number", title: "Рег. номер", tooltip: "Регистрационный номер ЭАД в СЭД", width: "194px", isNoWrap: false },
          {
            key: "status",
            title: "Статус",
            slotName: "status",
            tooltip: "Статус ЭАД может принимать следующие значения: Получен, Не получен",
            width: "70px",
            isNoWrap: true,
          },
          { key: "archive", title: "Архивный шифр", tooltip: "Архивный шифр дела", width: "240px", isNoWrap: true },
          { key: "name", title: "Наименование", tooltip: "Наименование ЭАД", width: "240px", isNoWrap: true },
          { key: "date", title: "Дата регистрации в СЭД", tooltip: "Дата регистрации в СЭД", width: "160px", isNoWrap: true },
        ],
      },
      folder: `${environmentVariables.BASE_URL}assets/images/common/foleder.svg`,
    };
  },
  methods: {
    ...mapActions("fundCatalogView", ["getTreeData", "getInventoryTreeData"]),
    openFund({ header, item }: { header: { isLink: boolean }; item: { code: string } }) {
      if (header.isLink) {
        this.$router.push({
          path: "/dictionaries/fund",
          query: {
            code: item.code,
            isOpenModal: "1",
          },
        });
      }
    },
    openInventory({ header, item }: { header: { isLink: boolean }; item: { header: string } }) {
      if (header.isLink) {
        this.$router.push({
          path: "/inventory/case-notes-list",
          query: {
            header: item.header,
            isOpenModal: "1",
          },
        });
      }
    },

    async fetchChildren(item: any) {
      this.openedItems = [...this.openedItems, item];
      if (item.totalLength && item.totalLength <= item.children) {
        return;
      }
      if (item.isFund) {
        await this.fetchInventories(item);
      }

      if (item.isInv) {
        await this.fetchCases(item);
      }

      if (item.isCase) {
        await this.fetchEads(item);
      }
    },

    openCase({ header, item }: { header: { isLink: boolean }; item: { name: string } }) {
      if (header.isLink) {
        this.$router.push({
          path: "/inventory/case",
          query: {
            header: item.name,
            isOpenModal: "1",
          },
        });
      }
    },
  },
  setup(props, { root }) {
    const fetchInventories = async (item: any) => {
      const { data, totalLength } = await root.$store.dispatch("fundCatalogView/getInventoryTreeData", {
        length: item.children?.length || 0,
        fundCode: item.code,
      });

      item.totalLength = totalLength;
      item.children = [
        ...(item.children || []),
        ...data.map((invTreeItem: any) => {
          return {
            title: invTreeItem.number,
            header: invTreeItem.header,
            type: invTreeItem.type,
            invId: invTreeItem.id,
            id: uuid(),
            kind: invTreeItem.kind,
            fund: item.number,
            isInv: true,
            isFolder: true,
            children: [],
          };
        }),
      ];
    };

    const fetchCases = async (item: any) => {
      const { data, totalLength } = await root.$store.dispatch("fundCatalogView/getCaseTreeData", {
        length: item.children?.length || 0,
        id: item.invId,
      });

      item.totalLength = totalLength;
      item.children = [
        ...(item.children || []),
        ...data.map((caseTreeItem: any) => {
          return {
            title: caseTreeItem.number,
            id: uuid(),
            caseId: caseTreeItem.id,
            name: caseTreeItem.header,
            isCase: true,
            isFolder: true,
            archive: caseTreeItem.archiveCipher,
            receivedEadCount: caseTreeItem.receivedEadCount,
            totalEadCount: caseTreeItem.totalEadCount,
            fundNumber: item.fund,
            index: caseTreeItem.index,
            children: [],
          };
        }),
      ];
    };

    const fetchEads = async (item: any) => {
      const { data, totalLength } = await root.$store.dispatch("fundCatalogView/getEadTreeData", {
        length: item.children?.length || 0,
        id: item.caseId,
      });

      item.totalLength = totalLength;
      item.children = [
        ...(item.children || []),
        ...data.map((eadTreeItem: any, index: number) => {
          return {
            id: index,
            isDoc: true,
            number: eadTreeItem.number,
            name: eadTreeItem.title,
            archive: eadTreeItem.archiveCipher,
            status: eadTreeItem.status,
            date: eadTreeItem.registrationDate,
            caseIndex: eadTreeItem.caseIndex,
            regNumber: eadTreeItem.id,
          };
        }),
      ].map((item, index) => {
        return {
          ...item,
          isCommonHeader: !index,
          isHideHeader: index,
        };
      });
    };

    const handleLoad = (item: any) => {
      if (item.totalLength && item.totalLength <= item.children.length) {
        return;
      }

      if (item.isFund) {
        fetchInventories(item);
      }

      if (item.isInv) {
        fetchCases(item);
      }

      if (item.isCase) {
        fetchEads(item);
      }
    };

    const { isLoadingOpenedItems, openedItems } = useDynamicTree({ handleLoad });

    return {
      isLoadingOpenedItems,
      openedItems,
      fetchInventories,
      fetchCases,
      fetchEads,
    };
  },
});
