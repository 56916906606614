














import { defineComponent, PropType } from "@vue/composition-api";
import { IEadElement } from "@monorepo/inventory/src/views/EadView/types/eadElement";
import { EadStatuses } from "@monorepo/inventory/src/views/EadView/types/eadStatuses";

export default defineComponent({
  name: "EadStatus",
  props: {
    id: {
      type: [String, Number],
      default: "",
    },
    data: {
      type: Array as PropType<IEadElement[]>,
      default() {
        return [];
      },
    },
  },
  computed: {
    status(): string {
      const item = this.data.find((item: IEadElement) => this.id === item.id);
      return item?.status?.toLowerCase() ?? "";
    },
    statusResult(): EadStatuses | null {
      if (!this.status) {
        return null;
      }

      switch (this.status) {
        case "получен":
          return EadStatuses.RECEIVED;
        case "не получен":
          return EadStatuses.NOT_RECEIVED;
        case "удалён":
          return EadStatuses.DELETED;
        default:
          return null;
      }
    },
    color(): string {
      switch (this.statusResult) {
        case EadStatuses.RECEIVED:
          return "#00A459";
        case EadStatuses.NOT_RECEIVED:
          return "#D34039";
        case EadStatuses.DELETED:
          return "#D34039";
        default:
          return "";
      }
    },
    text(): string {
      switch (this.statusResult) {
        case EadStatuses.RECEIVED:
          return "П";
        case EadStatuses.NOT_RECEIVED:
          return "Н";
        case EadStatuses.DELETED:
          return "У";
        default:
          return "";
      }
    },
  },
});
